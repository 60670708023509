.paf-loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin:auto;
  margin-bottom:50vh;
  left:0;
  right:0;
  top:0;
  bottom:0;
  position:fixed;
  z-index: 100;
  animation: paf-spin 2s linear infinite;
}

@keyframes paf-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-div {
  display:table;
  background:rgb(193,193,193,0.8);
  position:fixed;
  top:0px;
  left:0px;
  padding:30px;
  width:100%;
  height:100vh;
  z-index:10000
}

.loading-div-cell {
  height:50vh;
}

.loading-div-row {
  display:table-row;
  vertical-align:top;
  text-align:center;
  height:50vh;
}
.loading-div-row span {
  font-size: 16pt;
}